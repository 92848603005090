<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">订单管理</span>
			<div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
		</div>

		<el-card class="el-main">

			<le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
				<le-input label="手机号" v-model="pageParam.params.mobile" />
				<le-input label="设备号" v-model="pageParam.params.deviceCode" />
				<le-date-range label="下单时间" :minDate.sync="pageParam.params.startTime"
					:maxDate.sync="pageParam.params.endTime" />
				<le-input-num label="订单号" v-model="pageParam.params.orderId" />
				<!-- <le-input label="站点名" v-model="pageParam.params.stationName" /> -->
                <le-select-remote-search label="所属项目" v-model="pageParam.params.incomeId" :options="optionsIncome" placeholder="请选择项目(可搜索)" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
				<le-select-local-search label="订单状态" v-model="orderStatus" :options="options" />
				<le-select-local-search label="支付方式" v-model="pageParam.params.payChannel" :options="payChannelDic" />
				<le-select-local-search label="是否续单" v-model="pageParam.params.continueOrder" :options="continueOrderDic" />
			</le-search-form>

			<le-pagview ref="orderlist" @setData="setTableData" @pageChange='pageChange' :pageParam="pageParam" id="table_box" :isFixed="true" :tableRef="$refs.orderlisttabel">
				<el-table ref="orderlisttabel" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="orderId" label="序号" width="60">
                        <template slot-scope="scope">
							<span>{{ scope.$index + 1 + pageSize*(pageNum-1) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="orderId" label="订单号" width="220">
                        <template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start">
                                <div class="a-flex-rfsc">
                                    <el-image
                                        v-if="row.anxinService "
                                        style="width: 21px;height: 16px !important;margin-right: 5px;"
                                        fit='contain'
                                        src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/rest-charge-icon.png"></el-image>
                                    <span>{{ row.orderId }}</span>
                                    <!-- <span>{{ row.isFullTime==1?'(定时充)':'(充满自停)' }}</span> -->
                                    <span class="a-c-error">{{ row.continueOrder ? '(续单)' : '' }}</span>
                                </div>
                                <!-- <div class="a-flex-rfsc">
                                    <el-image
                                        style="width: 21px;height: 16px !important;margin-right: 5px;"
                                        fit='contain'
                                        src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/rest-charge-icon.png"></el-image>
                                    <span style="color: #FE7B11;" class="a-fs-26 a-fw-500">安心充服务保障中</span>
                                </div> -->
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="设备"  width="120">
						<template slot-scope="{ row }">
							<span>{{ row.deviceCode }}-{{ row.slot }}</span>
						</template>
					</el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="收款商户"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.payeeCompanyName?row.payeeCompanyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeName" label="所属项目" width="200" >
					</el-table-column>
					<el-table-column prop="stationName" label="站点" width="200" >
					</el-table-column>
					<el-table-column prop="userName" label="用户"  width="120">
					</el-table-column>
					<el-table-column prop="mobile" label="手机号"  width="120">
					</el-table-column>
					<el-table-column prop="orderSourceTypeText" label="来源"  width="120">
					</el-table-column>
					<el-table-column prop="payTypeText" label="支付方式" min-width="120">
					</el-table-column>
					<el-table-column label="订单金额" min-width="120">
						<template slot-scope="{ row }">
							<span>￥{{ util.numFormat(row.amount) }}</span>
						</template>
					</el-table-column>
                    <el-table-column label="实际支付" min-width="120">
						<template slot-scope="{ row }">
							<span>￥{{ util.numFormat(row.payAmount) }}</span>
						</template>
					</el-table-column>
                    <el-table-column label="优惠支付" min-width="120">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.discountPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column label="退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>现金：￥{{ util.numFormat(row.refundAmount) }}</span>
							    <span v-for="(item,index) in row.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
						</template>
					</el-table-column>
                    <el-table-column label="安心充支付金额" min-width="120">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start" v-if="row.restCharge">
                                <span>现金：￥{{ util.numFormat(row.restCharge.payAmount) }}</span>
							    <span v-for="(item,index) in row.restCharge.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.discountPayAmount) }}
                                </span>
                            </div>
                            <div v-else>-</div>
						</template>
					</el-table-column>
                    <el-table-column label="安心充退款" min-width="120">
						<template slot-scope="{ row }">
							<div class="a-flex-cfsfs" style="align-items: flex-start" v-if="row.restCharge">
                                <span>现金：￥{{ util.numFormat(row.restCharge.refundAmount) }}</span>
							    <span v-for="(item,index) in row.restCharge.orderDiscountInfoList" :key="index">
                                    {{ $Config.discountTypeDic[item.discountType] }}：￥{{ util.numFormat(item.refundPayAmount) }}
                                </span>
                            </div>
                            <div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column prop="statusText"  label="订单状态" >
					</el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="支付状态" 
                        column-key="payStatus">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
					<el-table-column label="计划时长" >
						<template slot-scope="{ row }">
							<span>{{ row.planMinutes }}分钟</span>
						</template>
					</el-table-column>
					<el-table-column label="实际时长" >
						<template slot-scope="{ row }">
							<span>{{ row.realMinutes }}分钟</span>
						</template>
					</el-table-column>
					<el-table-column label="开始时间"  width="160">
						<template slot-scope="{ row }">
							<span>{{ row.createTimeText || "" }}</span>
						</template>
					</el-table-column>
					<el-table-column label="结束时间"  width="160">
						<template slot-scope="{ row }">
							<span>{{ row.endTimeText || "" }}</span>
						</template>
					</el-table-column>

					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="{ row }">
							<div class="a-flex-rcc">
								<el-tooltip class="item" effect="dark" content="订单详细" placement="top">
									<div @click="handlerGoDetail(row)" class="a-cursor-p">
                                        <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
									</div>
								</el-tooltip>
                                <el-tooltip class="item a-ml-12" effect="dark" content="订单退款" placement="top"
                                    v-if="row.payStatus == 1 || row.payStatus == 2 || row.payStatus == 4">
									<div @click="refund(row)" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                        <img src="../../assets/icon/option-refund.png" class="a-wh-16" />
                                    </div>
								</el-tooltip>
                                <el-tooltip class="item a-ml-12" effect="dark" content="结束订单" placement="top"
									v-if="row.status == 1">
									<div @click="closeOrder(row.orderId)" v-if="userInfo.dataPermission == 99 || company.companyType == 2"> 
                                        <img src="../../assets/icon/option-refuse.png" class="a-wh-16" />
                                    </div>
								</el-tooltip>
                                <el-tooltip class="item a-ml-12" effect="dark" content="手动扣款" placement="top"
									v-if="row.status == 8">
									<div @click="managePay(row)"> 
                                        <img src="../../assets/icon/option-deduction.png" class="a-wh-16" />
                                    </div>
								</el-tooltip>
							</div>

						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</el-card>
        <cabinet-order-refund ref="cabinetOrderRefund" @oprateFinish="refundSuccess"></cabinet-order-refund>
	</div>
</template>

<script>
import util from "../../utils/util.js";
import { mapState } from 'vuex';
import CabinetOrderRefund from './child/cabinetOrderRefund.vue'
export default {
	mounted() { },
    components: {
        CabinetOrderRefund
    },
	data() {
		return {
			util: util,
			options: [{
				value: 1,
				label: '充电中'
			}, {
				value: 2,
				label: '已完成'
			}, {
				value: 3,
				label: '已取消'
			}, {
				value: 4,
				label: '下单失败'
			}, {
				value: 5,
				label: '支付超时'
			}, {
				value: 6,
				label: '申请退款'
			}],
			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.getOrderList,
				method: "post",
				params: {
					mobile: "",
					deviceCode: "",
					startTime: "",
					endTime: "",
					orderId: "",
					stationName: "",
                    stationId: '',
                    incomeId: '',
					status: [],
                    payChannel: "",
                    continueOrder: ""
				},
				freshCtrl: 1,
			},
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 100
            },//站点数据
            optionsIncome: {
                url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                method: "post",
                params: {
                    searchKey: "",
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 100
            },//项目数据
            pageSize: 20,
            pageNum: 1,
            payChannelDic: [],
            continueOrderDic: [],
            payStatusDic: [],
            orderStatus: ''
		};
	},
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
	mounted() { 
        this.$getDic('orderPayChannel','select').then(res=>{ this.payChannelDic = res; })
        this.$getDic('yesOrNo','select').then(res=>{ this.continueOrderDic = res; })
        this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
    },
	activated () {
		this.pageParam.freshCtrl++;
	},
	methods: {
		setTableData(data) {
			this.tableData = data.map(item=>{
                let restCharge
                if(item.superOrderItem) {
                    restCharge = item.superOrderItem.find(res=>{
                        return res.goodsType == 2
                    })
                }
                
                return {
                    ...item,
                    restCharge: restCharge
                }
            });
            console.log(this.tableData);
		},

        pageChange (datas) {
            this.pageSize = datas.pageSize
            this.pageNum = datas.pageNum
        },

		handlerRest() {
			this.pageParam.params = {
				mobile: "",
				deviceCode: "",
				startTime: "",
				endTime: "",
				orderId: "",
				stationName: "",
                stationId: '',
                incomeId: '',
				status: [],
                payChannel: '',
                continueOrder: ''
			};
            this.orderStatus = ''
			this.handlerSearch()
		},

		handlerSearch() {
			this.$refs['orderlist'].pageNum = 1
            this.pageParam.params.status = this.orderStatus?[this.orderStatus]:[]
			this.pageParam.freshCtrl++;
		},
        refundSuccess () {
            this.pageParam.freshCtrl++;
        },
		handlerGoDetail(datas) {
			this.$router.push({
				path: '/order/order-detail',
				query: {
					orderId: datas.orderId,
				}
			})
		},
		//订单退款
		async refund(row) {
            let datas = await this.getOrderInfoById(row)
            this.$refs['cabinetOrderRefund'].id = datas.orderId //订单id
            this.$refs['cabinetOrderRefund'].orderType = datas.orderType //订单类型
            this.$refs['cabinetOrderRefund'].originRefundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
            this.$refs['cabinetOrderRefund'].form.refundAmount = Number((((datas.payAmount || 0) - (datas.refundAmount || 0))/100).toFixed(2))
            this.$refs['cabinetOrderRefund'].form.refundDiscountVoList = datas.orderDiscountInfoList.filter(res=>{
                return res.discountType != 3 && res.discountType != 1 && res.discountType != 6 //退款时将vip优惠剔除出去
            }).map(item=>{
                return {
                    refundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    originRefundAmount: Number(((Number(item.discountPayAmount || 0) - Number(item.refundPayAmount || 0))/100).toFixed(2)),
                    discountType: item.discountType
                }
            })
            if(datas.superOrderItem) {
                this.$refs['cabinetOrderRefund'].superOrderItem = datas.superOrderItem.find(res=>{
                    return res.goodsType == 2
                }) //安心充订单
                this.$refs['cabinetOrderRefund'].superOrderCoupon = datas.superOrderItem.find(res=>{
                    return res.goodsType == 4
                }) //优惠券订单
            }
            
            this.$refs['cabinetOrderRefund'].dialogVisible = true
			// this.$confirm('确定进行订单退款（全额退还现金以及毛豆等优惠）?', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// }).then(() => {

			// 	this.$Axios._get({
			// 		url: this.$Config.apiUrl.refundOrder,
			// 		params: {
			// 			orderId: datas.orderId
			// 		},
			// 	}).then((res) => {
			// 		if (res.result.code == 0) {
			// 			this.$message({
			// 				type: 'success',
			// 				message: '订单开始退款!'
			// 			})
            //             this.pageParam.freshCtrl++;
			// 		} else {
			// 			this.$message({
			// 				type: 'error',
			// 				message: '退款失败：' + res.result.msg
			// 			})
			// 		}
			// 	}).catch((res) => {
			// 		this.$message({
			// 			type: 'error',
			// 			message: '退款失败：' + res.result.msg
			// 		})
			// 	})

			// }).catch(() => {

			// });
		},
        //获取充电桩订单详情
        getOrderInfoById(datas) {
            return new Promise((resolve, reject) => {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getOrderInfoById,
                    params: {
                        orderId: datas.orderId
                    },
                }).then((res) => {
                    if(res.result.code == 0){
                        resolve(res.result.data)
                    }else{
                        this.$message.error(res.result.message)
                        reject()
                    }
                }).catch(err=>{
                    this.$message.error(err.result.message)
                    reject()
                })
            })
        },
        refundPoint (datas) {
           
        },
        closeOrder (id) {
            this.$confirm('是否确定结束订单?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$Axios._post({
					url: this.$Config.apiUrl.socketCloserOrder,
                    method: "post",
					params: {
						orderId: id
					},
				}).then((res) => {
					if (res.result.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						})
                        this.pageParam.freshCtrl++;
					}
				}).catch((res) => {
					this.$message({
						type: 'error',
						message: '操作失败' + res.result.msg
					})
				})
			}).catch(() => {

			});
        },
        // 管理员手动扣款
        managePay (datas) {
            this.$confirm('是否确定手动扣款?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$Axios._post({
					url: this.$Config.apiUrl.orderRepay,
                    method: "post",
					params: {
						orderSn: datas.orderId,
                        orderType: datas.orderType
					},
				}).then((res) => {
					if (res.result.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						})
                        this.pageParam.freshCtrl++;
					}
				}).catch((res) => {
					this.$message({
						type: 'error',
						message: '操作失败' + res.result.msg
					})
				})
			}).catch(() => {

			});
        },
        exportfile () {
            if(!this.pageParam.params.stationId && !this.pageParam.params.mobile && !this.pageParam.params.deviceCode && !this.pageParam.params.orderId){
                this.$message.error('导出时站点、手机号、设备号、订单号不能都为空')
                return
            }
            if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length > 92){
                this.$message.error('最大导出范围3个月')
                return
            }
            let startDate = this.pageParam.params.startTime || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
            let endDate = this.pageParam.params.endTime || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
            this.$exfile({
                code: 16,
                fileName: startDate + ' 至 ' + endDate + ' 充电桩订单列表',
                startTime: startDate,
                endTime: endDate,
                params: {
                    ...this.pageParam.params,
                    startTime: startDate,
                    endTime: endDate
                }
            })
        }

	},
};
</script>

<style scoped lang="scss">
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
	/*width: 0;宽度为0隐藏*/
	width: 0 !important;
	height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
	height: 100% !important;
}
</style>
